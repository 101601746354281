(function () {
	$(document).ready(function () {
		var modal = $('.modal-cookies');

		if (!$('.modal-cookies').length) return false;

		var btn = modal.find('.accept-cookies');
		var settings = modal.find('.modify-cookies');

		var list = modal.find('.cookies-settings');

		settings.on('click', function (e) {
			e.preventDefault();

			//modal.find('.content p').slideToggle(750);

			//list.toggleClass('active');
			//list.slideToggle(750);

			return false;
		});

		btn.on('click', function (e) {
			e.preventDefault();

			var data = {
				tracking: list.find('.tracking-box').is(':checked'),
				remarketing: list.find('.remarketing-box').is(':checked'),
				profiling: list.find('.profiling-box').is(':checked')
			}

			Cookies.set('adjt', btoa(JSON.stringify(data)), {expires: 180});

			modal.addClass('close');
			setTimeout(function () { modal.remove(), 1000 });

			for (var key in data) {
				var el = $('script[data-' + key + ']');

				el.each(function () {
					if (!data[key]) {
						$(this).remove();
						return false;
					}

					var js = document.createElement('script');
					var attrs = this.attributes;

					for (var i = 0; i < attrs.length; i++) {
						if (attrs[i].name == 'data-' + key) continue;
						if (attrs[i].name == 'type') continue;
						js.setAttribute(attrs[i].name, attrs[i].value);
					}

					js.removeAttribute('data-' + key);

					if (js.src.length) document.body.insertAdjacentElement('beforeend', js);

					if (!js.src.length) {
						try {
							eval(this.innerHTML);
						} catch (e) {
							console.log(e);
							console.log(this);
							console.log(this.innerHTML);
						}
					}

					$(this).remove();
				});
			}

			return false;
		});
	});

	$(document).ready(function () {
		function sendEvent (el) {
			el = el.target;

			var cat = el.getAttribute('tkr-category'),
				act = el.getAttribute('tkr-action'),
				lbl = el.getAttribute('tkr-label');
			var hitType = el.getAttribute('tkr-event') || 'event';

			/*if (typeof ga == 'function') {
				ga('send', hitType, {
					eventCategory: cat,
					eventAction: act,
					eventLabel: lbl
				});
			}*/
			if (typeof gtag == 'function') {
				gtag(hitType, act, {
					'event_category': cat,
					'event_label': lbl
				});
			}
		}

		var trackers = document.querySelectorAll('[tkr]');

		if (trackers.length > 0) {
			for (var i = 0; i < trackers.length; i++) trackers[i].addEventListener('click', sendEvent);
		}

		(function () {
			var contactSection = $('main > section.contact');
			if (!contactSection.length) return false;
			var footer = $('.footer-desktop');

			contactSection.find('[href^="tel"],[href^="mailto"],[href*="/maps/"]').on('click', function(e){
				e.preventDefault();

				footer.find('[href="' + this.href + '"]')[0].click();
			})
		})();
	});
})();