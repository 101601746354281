// Contact map

var contactMap;
function initContactMap(){
		
	contactMap = new google.maps.Map( $('.contact-map').get(0) , {
      center: {lat: 50.839050, lng: 4.361406}, 
      zoom: 17,
      mapTypeControl: false
    });
    
    var infowindow = new google.maps.InfoWindow({
		content: $('#contact-window').html()
 	});
    
    var marker = new google.maps.Marker({
		position: {lat: 50.839050, lng: 4.361406},
		map: contactMap,
		title: 'Wolfers'
	});
	
	infowindow.open(contactMap, marker);
	marker.addListener('click', function() {
		infowindow.open(contactMap, marker);
	});
    
    contactMap.setOptions({
	    styles: [
          {
            featureType: 'poi.business',
            stylers: [{visibility: 'off'}]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
          }
        ]
	});

}

// SCROLL TO ID & #REMOVAL

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	  var target = $(this.hash);
	  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	  if (target.length) {
		$('html, body').animate({
		  scrollTop: target.offset().top
		}, 1500);
		return false;
	  }
	}
  });
});

// BURGER MENU

$('#click').change(function() {
	// if ($(this).is(':checked')) {
	// 	$("body").addClass("open");
	// } else {
	// 	$("body").removeClass("open");
	// }
	$('body').toggleClass('open');
});

$('.menu-trigger').click(function () {
	$('#click').trigger('click');
	return false;
});

// PRICE TAG

$('span.price').click(function () {
	$('div.price-tag').slideDown(300);
});

// MOVING LABEL − PAGE-CONTACT

$('.page-contact input').on('focus', function () {
	$(this).prev('label').addClass('activated');
	$(this).one('blur', function () {
		if ($(this).val().length == 0) {
			$(this).prev('label').removeClass('activated');
		}
	});
});

// FORM NOT BLOCKER


$('.page-contact form input').focus(function(){ $(this).removeClass('error-warning'); });
$('.page-contact form').submit(function(){
	
	var error = false;	
	
	$(this).find('input[type=text], input[type=email]').each(function(){
		if($(this).val().trim().length <= 0 ){
			error = true;
			$(this).addClass('error-warning');
		}
	});
	
	return !error;
});

$('.modal .close').click(function(){		
		$(this).parents('.modal').fadeOut(function(){
			$(this).remove();
		});
		
		return false;
	});

// VIDEO OPENER

$('.play-video').click(function () {
	var videoOrigin = $(this).data('video-origin');
	var videoId = $(this).data('video-id');
	$('body').append('<div class="videoModal"><div class="videoBackground"></div><div class="videoOuterBox"><div class="videoInnerBox"></div></div></div>');

	switch (videoOrigin) {
		case 'youtube' :
			$('.videoInnerBox').append('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
			break;

		case 'vimeo' :
			$('.videoInnerBox').append('<iframe src="https://player.vimeo.com/video/' + videoId + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
			break;
	}

	$('.videoModal').slideDown(400);

	setTimeout(function () {
		$('.videoInnerBox').addClass('active');
	}, 500);

	$('body').one('click', '.videoBackground', function () {
		$('.videoModal').slideUp(function () {
			$(this).remove();
		});
	});

	return false;
});

// LIST CONTENT HEIGHT GENERATOR

$(window).resize(function(){
	$('.list-content-block').each(function() {
		$(this).height($(this).width());
	});
}).resize();

var iWindowsSize = window.innerWidth;
if (iWindowsSize  < 860 && iWindowsSize >=550){
	document.getElementBy("myDIV").style.height = "500px";
}
// GOTO

$(document).ready(function(){
	if ($(window).width() < 700){
			$('.list-content-block').height(250);
	}
});

$('h1.logo').click(function(){
  window.location = '/';
});



var swprs = {};
$(document).ready(function(){

	var firstClick = 0;

	// Product swipers

	$('.product .swiper-container').each( function(){		
		swprs[ $(this).attr('id') ] = new Swiper ( $(this).get(0) );
	});

	// History swiper

	var historySwiper = new Swiper ( '.history .swiper-container' );

	historySwiper.on('slideChangeTransitionStart', function () {
		var si = this.activeIndex;

		console.log();

		$('.dates li.active, .history-texts li.active').removeClass('active');
		$($('.dates li').get(si)).addClass('active');
		$($('.history-texts li').get(si)).addClass('active');
	});

	$('.dates a').on('click', function () {
		var di = $(this).parent().index();

		if (di != historySwiper.activeIndex) {
			historySwiper.slideTo(di, 650);
		}

		return false;
	});

	$('.history ul.slider li').on('click', function () {
		if ($(this).hasClass('previous')) {
			historySwiper.slidePrev();
		} else {
			historySwiper.slideNext();
		}
	});
	
	// Click Group
	
	$('ul.inner-content-option.groups li').click( function(){
		
		var group = $(this).data('group');
		var label = $(this).data('label');
		
		$(this).parent().find('.group-name').text(label);
		
		var children = $('ul.inner-content-option.colors li.' + group);
		$('ul.inner-content-option.colors li').hide();
		children.show();
		$(children.get(0)).trigger('click');
		
		$('.product ul.inner-content-option.groups li').removeClass('active');	
		$(this).addClass('active');

		if (window.innerWidth < 768 && firstClick > 0) {
			$('html, body').animate({'scrollTop': $('.split:visible').offset().top}, 300);
		}

		console.log(firstClick);

		firstClick++;
	});
	
	$('ul.inner-content-option.colors li').click(function(){
		
		var label 	= $(this).data('label');
		var price 	= $(this).data('price');
		var id 		= $(this).data('id');
		
		$('.product-description').hide();
		$('.' + id).fadeIn();
		
		$(this).parent().find('.color-name').text(label);
		
		switch(true) {
			
			case price == 0:
			
		}
		
		if( undefined == price || parseInt(price) == 0 || isNaN(parseInt(price)) ) {
		
			$('.price-tag, .price').hide();
		} else {
			$('.price-tag').show();
			$('span.price').show().html(price + '€');
		}
		
		$('.product ul.inner-content-option.colors li').removeClass('active');	
		$(this).addClass('active');
		
		$('.product .swiper-container').hide();
		$('.product .swiper-container#' + id).show();
		
		var slides = $('#' + id).find('.swiper-slide');
		if( slides.length <= 1 ) {
			$('ul.slider').hide();
		} else {
			$('ul.slider').show();
		}

		if (window.innerWidth < 768 && firstClick > 0) {
			$('html, body').animate({'scrollTop': $('.split:visible').offset().top}, 300);
		}
		
	});
	
	$($('ul.inner-content-option.groups li').get(0)).trigger('click');

	/*$('.product ul.inner-content-option li').click(function(evt){
		
		$('.product ul.inner-content-option li').removeClass('active');	
		$(this).addClass('active');
		
		$(this).parent().find('span').html( $(this).data('label') );
		
		var price = $(this).data('price');
		if(price) {
			price = price + '';
			$('.product span.price').html(  price + ( price.indexOf('€') < 0 ? '€' : '' ) );
		}
		
		$('.product .swiper-container, .product .product-description').hide();
		$('.' + $(this).data('slug') ).each( function(){
			$(this).stop().fadeIn().show();
		});	
		
	});
	
	$('.product ul.inner-content-option').first().find('li').first().trigger('click'); */
	
	$('.product ul.slider li').click( function(){
		
		var active = $('.product .inner-content-option.colors li.active').data('id');
		var swpr = swprs[active];
		
		console.log(active);
		
		if( $(this).hasClass('next') ) swpr.slideNext();
		if( $(this).hasClass('previous') ) swpr.slidePrev(); 
		
	});
	
	// Home slider
	
	var homeSwpr = new Swiper ('.homepage .swiper-container', {
		loop: true,
		speed: 1750,
		autoplay: {
	  		delay: 5500
  		}
	});
	
	$('.homepage ul.slider li').click( function(){
		if( $(this).hasClass('next') ) homeSwpr.slideNext();
		if( $(this).hasClass('previous') ) homeSwpr.slidePrev(); 
	});

	$('a.return-top').click(function (e) {
		e.preventDefault();
		$('html, body').animate({'scrollTop': 0}, 1000);
		return false;
	});

});
$('.btn-newsletter').click( function(){
	$('.thanks').fadeIn();
	$('.modal-thanks').removeClass('modal-thanks_close');
});

$('.btn-newsletter-close').click( function(){
	$('.modal-thanks').addClass('modal-thanks_close');
	$('.thanks').delay(1000).fadeOut();
	// $('.thanks').delay( 800 ).fadeIn( 400 ).addClass('thanks_close');
});
$('.btn-choice ').click(function() {
	$(this).parents('.category').toggleClass('active');
});


$('.cookie_infos').click(function() {
	$(".modal-gdpr").addClass("show");
});

$('.close').click(function() {
	$(".modal-gdpr").removeClass("show");
});

$('.modal-covid_close' ).click(function() {
    $('.block_covid').fadeOut(300);
  });
  
$('.open-cookie').click(function() {
	$(".cookie-bar").removeClass("noshow");
	return false;
});
// $(document).ready(function(){
		
// 	$('.submit-form').submit(function(){

		
		
// 		$(this).find('.required').each(function(){
// 			if( $(this).val().length <= 0 ) {
// 				$(this).addClass('error-warning');
// 				error = true;
// 			}
// 		});
		
// 		return !error;
// 	});
// });



// SCROLL REVEAL.JS

// window.sr = ScrollReveal({ 

//   reset:        true, 
//   mobile:       false, 
//   viewFactor:   0.05,
//   duration:     800,
//   origin:       'bottom',
//   distance:     '10px',

// });

// PRODUCT OPTIONS


// MENU

//sr.reveal('.menu-content ul li a'   ,  { viewFactor: 0 }, 20);

// VIEWPORT DELAY

//sr.reveal('.viewport h1'            , {distance:'0px' , delay: 0   });
sr.reveal('.viewport .offical img'  , {distance:'0px' , delay: 50  });
//sr.reveal('.viewport .menu-holder'  , {distance:'0px' , delay: 100 });
sr.reveal('.viewport .slider'       , {distance:'0px' , delay: 200 });
sr.reveal('.viewport .lang'         , {distance:'0px' , delay: 300 });
sr.reveal('.viewport .social'       , {distance:'0px' , delay: 400 });
sr.reveal('.viewport .scroll'       , {distance:'0px' , delay: 500 });

// CONTENT DELAY

sr.reveal('.content-category-image'   , {delay: 0   });
sr.reveal('.content-category-txt'     , {delay: 10  });
sr.reveal('.content-category-txt h2'  , {delay: 50  });
sr.reveal('.content-category-txt p'   , {delay: 100 });
sr.reveal('.content-category-txt a'   , {delay: 150 });
sr.reveal('.content-category span'    , {delay: 200 });

// CONTACT DELAY
// Removed because compatibility problems with Google Maps

//sr.reveal('.contact-content'          , {delay: 0   });
//sr.reveal('.contact-map'              , {delay: 50  });
//sr.reveal('.contact-content h2'       , {delay: 50  });
//sr.reveal('.contact-content p'        , {delay: 100 });
//sr.reveal('.contact-content a'        , {delay: 150 });
//sr.reveal('.contact-content span'     , {delay: 200 });
//sr.reveal('.contact-newsletter'       , {delay: 0   });
//sr.reveal('.contact-newsletter h2'    , {delay: 50  });
//sr.reveal('.contact-newsletter p'     , {delay: 100 });
//sr.reveal('.contact-newsletter a'     , {delay: 150 });
//sr.reveal('.contact-newsletter span'  , {delay: 200 });

// FOOTER

sr.reveal('footer'          , {delay: 0   });
sr.reveal('footer ul li a'  , 20           );
sr.reveal('footer img'      , {delay: 0   });
sr.reveal('footer p'        , {delay: 0   });

// INNER PAGE
//sr.reveal('.split'                            , {delay: 0   });
//sr.reveal('.inner-content h2'                 , {delay: 0   });
sr.reveal('.inverse h2'                       , {delay: 0   });
sr.reveal('.inner-content p'                  , { viewFactor: -10 }, 20);
sr.reveal('.inner-content a'                  , { viewFactor: -20 }, 20);
sr.reveal('.inner-content-block'              , {delay: 0   });
sr.reveal('.inner-content-block-img'          , {delay: 50  });
sr.reveal('.inner-content-block-txt'          , {delay: 100 });
sr.reveal('.inner-content-block-txt h3'       , {delay: 150 });
sr.reveal('.inner-content-block-txt p'        , {delay: 200 });
//sr.reveal('.inner-content-block-txt a'        , {delay: 250 });

// LIST

sr.reveal('.list-content-block-img'            , {delay: 0   });
sr.reveal('.list-content-block'                , 20           );
sr.reveal('.list-content-block-txt h3'         , {delay: 50  });
sr.reveal('.list-content-block-txt p'          , {delay: 100 });
//sr.reveal('.list-content-block-txt a'          , {delay: 150 });

// PRODUCT

//sr.reveal('.inner-content-option li'           , 20           );
//sr.reveal('.inner-content-option span'         , {delay: 150 });
//sr.reveal('.price'                             , {delay: 200 });

